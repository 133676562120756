import { fetchEndpoint,fetchGetPoint} from "@/server/network1";
/**
 * fetchEndpoint
 *  @url    api 接口路径
 *  @data   接口传值
 *  @type   true json传参 /false form传参  ,默认json 可不填
 *  @fun    'GET'/'POST'  默认POST 可不填
 */

//出勤率统计
export const getPercentageOfAttendance = (data) => fetchGetPoint(
    "/school/bureau/attendance/getPercentageOfAttendance", { ...data }, false, 'GET'
)
//每日详细数据
export const getStudentAttendance = (data) => fetchGetPoint(
    "/school/bureau/attendance/getStudentAttendance", { ...data }, false, 'GET'
)
